import axios from '../libs/axios';

const storeList = (data) => {
  return axios.post('/store/list', data);
};

const storeInfo = (data) => {
  return axios.post('/store/info', data);
};

const storeSubmit = (data) => {
  return axios.post('/store/submit', data);
};

// 个人拥有的门店
const getOwnShopList = (data) => {
  return axios.get('/api/trd/shop/own', data);
};

export {
  storeList,
  storeInfo,
  storeSubmit,
  getOwnShopList
};
