<template>
  <a-select
    allowClear
    placeholder="请选择"
    optionFilterProp="children"
    v-model="storeIdList"
    @change="handleChange">
    <a-select-option
      v-for="(item) in storeList"
      :key="item.shopId"
      :value="item.shopId">
      {{item.shopName}}
    </a-select-option>
  </a-select>
</template>

<script>
import { getOwnShopList } from '@/api/store';

export default {
  name: 'StoreListSelect',
  components: {},
  props: ['value'],
  data() {
    return {
      storeIdList: [],
      storeList: [],
    };
  },
  computed: {},
  watch: {
    value(val) {
      this.storeIdList = val;
    }
  },
  created() {
    this.getOwnStoreList();
  },
  methods: {
    getOwnStoreList() {
      getOwnShopList().then((data) => {
        this.storeList = data;
      });
    },
    handleChange(value) {
      this.$emit('input', value);
      this.$emit('on-change', value);
    },
    filterOption(value, op) {
    }
  },
};
</script>

<style lang="less" scoped>

</style>
