<!-- 统一table样式 -->
<template>
  <a-table
    bordered
    ref="atable"
    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onChange }"
    :columns="columns"
    :dataSource="dataSource"
    :pagination="false"
    :scroll="{ x: 1000 }"
  >
  </a-table>
</template>

<script>
export default {
  name: 'ITable',
  components: {},
  props: {
    columns: {
      type: Array,
      require: true,
    },
    dataSource: {
      type: Array,
      require: true,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedRowKeys: [],
      selectedKeys: [],
    };
  },
  computed: {
    // rowSelection() {
    //   return {
    //     getCheckboxProps: (record) => ({
    //     }),
    //   };
    // },
  },
  watch: {},
  created() {},
  mounted() {},
  destroyed() {},
  methods: {
    resetSelect() {
      this.selectedRowKeys = [];
    },
    onChange(selectedRowKeys, selectedRows) {
      this.selectedKeys = [];
      this.selectedRowKeys = selectedRowKeys;
      for (let i = 0; i < selectedRows.length; i++) this.selectedKeys.push(selectedRows[i].id);
      this.$emit('selectRowChange', this.selectedKeys);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows
      );
    },
  },
};
</script>

<style lang="less" scoped>
</style>
