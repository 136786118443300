<template>
  <a-range-picker
    v-model="duration"
    format="YYYY-MM-DD"
    :placeholder="['开始时间', '结束时间']"
    :disabled-date="disabledDate"
    :allowClear="allowClear"
    @change="onChange">
  </a-range-picker>
</template>

<script>
import moment from 'moment';

export default {
  name: 'IDatePicker',
  components: {},
  props: {
    value: {

    },
    allowClear: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      duration: []
    };
  },
  computed: {},
  watch: {
    value: {
      handler(val) {
        if (val && val.length) {
          this.duration = [moment(val[0]), moment(val[1])];
        }
      },
      immediate: true
    }
  },
  created() {
  },
  methods: {
    onChange(date, formatDate) {
      const fmtDate = (date && date.length > 0) ? formatDate: [];
      this.$emit('input', fmtDate);
    },

    // 不可选择的日期
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
  },
};
</script>

<style lang="less" scoped>

</style>
