<template>
  <a-modal
    v-model="_visible"
    width="800px"
    :maskClosable="false"
    :dialog-style="{ top: '10px' }"
    title="生成优惠券"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form-model
      ref="ruleForm"
      :model="addForm"
      :rules="rules"
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 21 }"
    >
      <a-form-model-item label="类型" prop="types" style="margin-bottom: 10px">
        <a-radio-group :options="typeOption" v-model="addForm.types">
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="名称" prop="name" style="margin-bottom: 10px">
        <a-input v-model="addForm.name" />
      </a-form-model-item>
      <a-form-model-item
        label="选取优惠券"
        v-if="addForm.types === 4"
        style="margin-bottom: 10px"
      >
        <j-select-multiple v-model="addForm.lbids" :options="validCouponData" />
        <!-- <a-select
          mode="multiple"
          style="width: 100%"
          v-model="addForm.lbids"
          placeholder="请选择优惠券"
        >
          <a-select-option v-for="i in validCouponData" :key="i.id">
            {{ i.name }}
          </a-select-option>
        </a-select> -->
      </a-form-model-item>
      <a-form-model-item label="配置优惠券" v-if="addForm.types === 4">
        <a-table
          size="small"
          bordered
          :rowKey="'value'"
          :pagination="false"
          :scroll="{ y: 150 }"
          :columns="couponsColumns"
          :data-source="couponDatasoure"
        ></a-table>
      </a-form-model-item>
      <a-form-model-item
        label="金额"
        prop="cash"
        v-if="addForm.types !== 4 && addForm.types !== 1"
        style="margin-bottom: 10px"
      >
        <a-input-number v-model="addForm.cash"></a-input-number>
      </a-form-model-item>
      <a-form-model-item label="有效期" style="margin-bottom: 10px">
        <a-radio-group v-model="addForm.endflag" @change="setRemain">
          <a-radio-button :value="0"> 自定义有效时长 </a-radio-button>
          <a-radio-button :value="1"> 选择日期 </a-radio-button>
          <a-radio-button :value="2"> 长期有效 </a-radio-button>
        </a-radio-group>
        <section>
          <template v-if="+addForm.endflag === 0">
            有效天数：<a-input-number
              v-model="addForm.days"
              :min="1"
            ></a-input-number>
          </template>
          <template v-if="+addForm.endflag === 1">
            <a-range-picker
              v-model="addForm.indate"
              format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"
              :disabled-date="disabledDate"
              :allowClear="true"
            >
            </a-range-picker>
          </template>
        </section>
      </a-form-model-item>
      <a-form-model-item label="数量" prop="cnt" style="margin-bottom: 10px">
        <a-input-number v-model="addForm.cnt" :min="1"></a-input-number>
      </a-form-model-item>
      <a-form-model-item
        label="使用条件"
        prop="conditioncash"
        style="margin-bottom: 10px"
      >
        <a-input-number
          v-model="addForm.conditioncash"
          placeholder="满足多少金额可用"
        ></a-input-number>
      </a-form-model-item>
      <a-form-model-item
        label="使用范围"
        prop="userange"
        style="margin-bottom: 10px"
        v-if="addForm.types !== 5"
      >
        <j-category-car-select
          ref="carSelect"
          :multiple="true"
          :isCheckAll="true"
          placeholder="请选择车辆分类"
          :treeCheckable="true"
          v-model="addForm.userange"
        />
      </a-form-model-item>
      <a-form-model-item
        label="叠加张数"
        prop="sharecnt"
        style="margin-bottom: 10px"
        v-if="addForm.types !== 5"
      >
        <!-- 默认一张 TODO: -->
        <a-input-number
          v-model="addForm.sharecnt"
          :min="1"
          placeholder="满足多少金额可用"
        ></a-input-number>
      </a-form-model-item>
      <a-form-model-item
        label="当天是否可用 ："
        prop="dayFlag"
        style="margin-bottom: 10px"
      >
        <a-switch v-model="addForm.dayFlag" />
      </a-form-model-item>
      <a-form-model-item
        label="假期是否可用 ："
        prop="holidayFlag"
        style="margin-bottom: 10px"
      >
        <a-switch v-model="addForm.holidayFlag" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import moment from 'moment';
import IDatePicker from '../../components/IDatePicker.vue';
import JCategoryCarSelect from '@/components/JCategoryCarSelect';
import { getValidCouponList } from '@/api/coupon';
import { getAction } from '@/api/manage';
import JSelectMultiple from '@/components/JSelectMultiple';

export default {
  name: 'addCoupon',
  components: {
    IDatePicker,
    JSelectMultiple,
    JCategoryCarSelect,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      typeOption: [
        { label: '体验券', value: 1 },
        { label: '优惠券', value: 2 },
        { label: '转赠券', value: 3 },
        { label: '礼包', value: 4 },
        { label: '充值券', value: 5 },
      ],
      couponDatasoure: [],
      couponsColumns: [
        {
          title: '优惠券名称',
          dataIndex: 'label',
          key: 'label',
          ellipsis: true,
          width: 150,
        },
        {
          title: '数量',
          dataIndex: 'cnt',
          key: 'cnt',
          ellipsis: true,
          width: 100,
          customRender: (text, row, index) => {
            return (
              <section>
                <a-input-number
                  {...{
                    attrs: {
                      min: 1,
                      step: 1,
                      max: 100,
                      value: text,
                    },
                  }}
                  vOn:change={(val) => this.handleChange(val, row)}
                  style="width: 100%;"
                ></a-input-number>
              </section>
            );
          },
        },
      ],
      rules: {
        name: [
          { required: true, message: '请输入优惠券名称', trigger: 'blur' },
        ],
        types: [
          { required: true, message: '请选择优惠券类型', trigger: 'blur' },
        ],
        userange: [{ validator: this.handlePass, trigger: 'blur' }],
        sharecnt: [
          { required: true, message: '请输入最大使用张数', trigger: 'blur' },
        ],
        conditioncash: [
          { required: true, message: '满足多少金额可用', trigger: 'blur' },
        ],
        holidayFlag: [
          { required: true, message: '请选择节假日是否可用', trigger: 'blur' },
        ],
        dayFlag: [
          { required: true, message: '请选择当天是否可用', trigger: 'blur' },
        ],
        cash: [{ required: true, message: '请输入金额', trigger: 'blur' }],
        cnt: [{ required: true, message: '请输入优惠券数量', trigger: 'blur' }],
      },
      addForm: {
        name: '',
        types: 1,
        indate: [],
        cash: 0,
        cnt: 1,
        days: 7,
        range: '',
        holidayFlag: true,
        conditioncash: 0,
        sharecnt: 1,
        dayFlag: true,
        userange: '',
        lbids: '',
        lbcnts: '',
        endflag: 0,
      },
      validCouponData: [],
      allurl: '/api/sys/car/category/loadAllTreeRoot',
      allCarCatgoryData: [],
    };
  },
  computed: {
    _visible: {
      get() {
        // this.clearForm()
        return this.visible;
      },
      set() {},
    },
  },
  watch: {
    'addForm.lbids': {
      handler(a, b) {
        this.lbSelect();
        console.log('addCoupon++++++++++++++++++++');
      },
    },
    form: {
      handler(nv, ov) {
        if (nv) {
          this.addForm = JSON.parse(JSON.stringify(nv));
        }
        // console.log('addCoupon++++++++++++++++++++',this.addForm,this.addForm.name)
      },
      deep: true,
    },
  },
  mounted() {
    // console.log('this.$refs.carSelect',this.$refs.carSelect)
  },
  created() {
    console.log('addcoupon create');
    this.loadValidCouponList();
    this.loadAllCar();
  },
  methods: {
    lbSelect() {
      let arrCnt = [];
      if (this.addForm.lbcnts !== undefined && this.addForm.lbcnts !== '' && this.addForm.lbcnts != null) {
        arrCnt = this.addForm.lbcnts.split(',');
      }
      this.couponDatasoure = [];
      let arr = [];
      if (this.addForm.lbids !== undefined && this.addForm.lbids !== '' && this.addForm.lbids != null) {
        arr = this.addForm.lbids.split(',');
      }
      const TmpArr = [];
      for (let i = 0; i < arr.length; i++) {
        for (let k = 0; k < this.validCouponData.length; k++) {
          if (
            this.validCouponData[k].value === arr[i]
            && this.validCouponData[k].types !== 4
          ) {
            const item = {};
            item.label = this.validCouponData[k].label;
            item.value = this.validCouponData[k].value;
            let Icount = 1;
            for (let m = 0; m < arrCnt.length; m++) {
              const strCnt = arrCnt[m];
              const pos = strCnt.indexOf(item.value);
              if (pos >= 0) {
                Icount = strCnt.substring(pos + item.value.length + 1);
                break;
              }
            }
            item.cnt = Icount;
            TmpArr.push(item);
            break;
          }
        }
      }
      this.couponDatasoure = JSON.parse(JSON.stringify(TmpArr));
    },
    handleChange(val, row) {
      row.cnt = val;
    },
    handlePass(rule, value, callback) {
      if (this.addForm.types !== 5 && value === '') callback('请选择车辆类型');
      callback();
    },
    clearForm() {
      this.addForm = {
        name: '',
        types: 1,
        indate: [],
        cash: 0,
        days: 7,
        lbids: '',
        lbcnt: '',
        cnt: 1,
        range: '',
        holidayFlag: true,
        conditioncash: 0,
        sharecnt: 1,
        dayFlag: true,
        userange: '',
      };
    },
    handleOk() {
      if (this.addForm.endflag == 0 && this.addForm.days <= 0) {
        this.$message.warning('有效天数不能为空');
        return;
      }
      if (this.addForm.endflag == 1 && this.addForm.indate.length == 0) {
        this.$message.warning('时间不能为空');
        return;
      }
      if (this.addForm.userange == '' && this.addForm.types !== 5) {
        this.$message.warning('使用范围不能为空');
        return;
      }
      if (
        this.addForm.cash <= 0
        && this.addForm.types !== 4
        && this.addForm.types !== 1
      ) {
        console.log(this.addForm.types);
        this.$message.warning('金额必须大于零');
        return;
      }
      // 体验券不可共用
      if (this.addForm.types === 1) {
        this.addForm.cash = 0;
        this.addForm.sharecnt = 1;
      }
      if (this.addForm.types === 5) {
        this.addForm.userange = '';
        this.addForm.sharecnt = 1;
      }
      // 礼包
      if (this.addForm.types === 4) {
        console.log('lb cnt');
        this.addForm.lbcnts = '';
        for (let i = 0; i < this.couponDatasoure.length; i++) {
          const item = this.couponDatasoure[i];
          if (this.addForm.lbcnts === '') this.addForm.lbcnts = `${item.value}:${item.cnt}`;
          else this.addForm.lbcnts = `${this.addForm.lbcnts},${item.value}:${item.cnt}`;
        }
      }

      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit('ok', this.addForm);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleCancel() {
      this.$emit('cancel');
    },
    setRemain() {
      console.log('点不动？');
      console.log('this.addForm.endflag', this.addForm.endflag);
      this.addForm.indate = [];
      if (this.addForm.endflag === 2) {
        this.addForm.indate = [moment().format('YYYY-MM-DD'), '9999-12-30'];
        this.days = 0;
      }
    },
    // 不可选择的日期
    disabledDate(current) {
      return current < moment().subtract(1, 'day');
    },

    loadValidCouponList() {
      const that = this;
      this.validCouponData = [];
      console.log('loadValidCouponList');
      const param = {
        types: '1,2,3',
      };
      const datestr = moment(new Date()).format('YYYY-MM-DD');
      getValidCouponList(param).then((res) => {
        for (let i = 0; i < res.length; i++) {
          const kitem = res[i];
          if (
            kitem.endflag == 1
            && kitem.endTime !== undefined
            && kitem.endTime < datestr
          ) continue;
          const item = {};
          item.value = res[i].id;
          item.label = res[i].name;
          that.validCouponData.push(item);
        }
      });
    },
    loadAllCar() {
      const that = this;
      const param = {
        async: false,
        pid: '0',
      };
      getAction(this.allurl, param).then((res) => {
        if (res !== undefined) {
          that.allCarCatgoryData = [...res];
          const temp = [];
          for (let i = 0; i < res.length; i++) {
            temp.push(res[i].value);
          }
          that.addForm.userange = temp.join(',');
        } else {
          console.log('树一级节点查询结果-else', res);
        }
      });
    },
  },
};
</script>

<style>
</style>
