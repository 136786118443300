import Axios from 'axios';
import axios from '../libs/axios';

// 获取优惠券列表
export const couponList = (data) => {
  return axios.get('/api/trd/trdCoupon/list', data);
};

// 添加修改优惠券

export const editCoupon = (data) => {
  return axios.post('/api/trd/trdCoupon/addEdit', data);
};

// 删除优惠券
export const deleteCoupon = (ids) => {
  return axios.delete(`/api/trd/trdCoupon/deleteBatch?ids=${ids}`);
};

// 获取有效的优惠券列表

export const getValidCouponList = (types) => {
  return axios.get('/api/trd/trdCoupon/selectCoupons', types);
};

export const getCouponMember = () => {
  return axios.get('/api/trd/trdCoupon/getCouponMemberList');
};

// 下发优惠券
export const addUserCoupon = (data) => {
  return axios.post('/api/trd/trdCoupon/addUserCoupon', data);
};

// 获取会员优惠券列表
export const getMemberCouponListById = (data) => {
  return axios.get('/api/trd/trdCoupon/getCouponsByMemberId', data);
};
export const getMemberCouponUsedByMemberId = (data) => {
  return axios.get('/api/trd/trdCoupon/getCouponsUsedByMemberId', data);
};
export const getAllCouponsByMemberId = (data) => {
  return axios.get('/api/trd/trdCoupon/getAllCouponsByMemberId', data);
};
export const checkHoliday = () => {
  return axios.get('/api/trd/trdCoupon/checkHoliday');
};
export const getRechageCouponlistByRuleId = (data) => {
  return axios.get(`/api/trd/trdCoupon/getRechageCouponlistByRuleId?RuleId=${data}`);
};
// 订单导出
export const reportExport = () => {
  return axios.get('/api/trd/trdCoupon/ExportMemberCoupon');
};
