/**
 * Created by tudou on 2020/4/21 22:19.
 */
import axios from '../libs/axios';

const memberList = (data) => {
  return axios.get('/api/trd/member/list', data);
};

// 通过手机号或者会员卡号获取用户信息
const getByCodeOrMobile = (data) => {
  return axios.get(`/api/trd/member/getByCodeOrMobile/${data}`);
};

// 查看会员信息
const memberInfo = (memberId) => {
  return axios.get(`/api/trd/member/info/${memberId}`);
};

// 新建会员
const memberAdd = (data) => {
  return axios.post('/api/trd/member/save', data);
};

// 启用或者禁用会员
// /api/trd/member/status/{memberId}/{status}
const memberStatus = (data) => {
  return axios.post(`/api/trd/member/status/${data.memberId}/${data.status}`);
};
// 会员信息导出
const membersExport = (data) => {
  return axios.post('/api/trd/member/export', data);
};

// 会员场次
const memberMatch = (data) => {
  return axios.post('/api/trd/member/match', data);
};

// 会员成绩
const memberScore = (data) => {
  return axios.post('/api/trd/member/score', data);
};

// 会员消费记录
const memberBill = (data) => {
  return axios.post('/api/trd/member/bill', data);
};

// 会员消费记录
const memberTimesCardLog = (data) => {
  return axios.get('/api/trd/timesCard/log', data);
};
// 会员退款
const memberRefund = (data) => {
  return axios.post('/api/trd/recharge/remainRefund', data);
};
// 更新会员年卡头像
const memberUpdateTimePicture = (data) => {
  return axios.post('/api/trd/member/update', data);
};
// 新建折扣卡
const discountCardAdd = (data) => {
  return axios.post('/api/trd/member/discount/save', data);
};
// 根据手机号查询折扣信息
const queryDiscountByMobile = (mobile) => {
  return axios.get(`/api/trd/member/discount/mobile/${mobile}`);
};
// 根据手机号查询有效折扣信息
const queryEffectiveByMobile = (mobile) => {
  return axios.get(`/api/trd/member/discount/effective/${mobile}`);
};
export {
  memberList,
  memberInfo,
  getByCodeOrMobile,
  memberAdd,
  memberStatus,
  membersExport,
  memberMatch,
  memberScore,
  memberBill,
  memberRefund,
  memberTimesCardLog,
  memberUpdateTimePicture,
  discountCardAdd,
  queryDiscountByMobile,
  queryEffectiveByMobile
};
